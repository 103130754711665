<template>
  <div class="mb-3">
    <div class="d-flex flex-wrap">
      <div v-for="user in usersFiltered" :key="user.id" class="mb-2">
        <div class="d-flex align-items-center p-2" style="width: 372px">
          <div v-if="userIsSelected(user)" class="mr-3">
            <ButtonCircle name="check" size="20" @click="unautorizeUser(user)" :loading="loading"></ButtonCircle>
          </div>
          <div v-if="!userIsSelected(user)" class="mr-3">
            <ButtonCircle
              name="lock"
              size="20"
              @click="autorizeUser(user)"
              :loading="loading"
              class="text-danger"
            ></ButtonCircle>
          </div>
          <app-avatar
            class="d-flex align-items-center mr-2"
            :url="user.collaborateur_avatar"
            propSize="3rem"
          ></app-avatar>
          <div class="mr-3 w-100" style="line-height: 1.1rem">
            <div>{{ user.collaborateur_nom }} {{ user.collaborateur_prenom }}</div>
            <div>
              <small class="text-success" v-if="userIsSelected(user)">A accès à l'élément</small>
              <small class="text-danger" v-if="!userIsSelected(user)">N'a pas accès à l'élément</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import AppAvatar from "@/components/bases/Avatar.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  components: { AppAvatar, ButtonCircle },
  data() {
    return {
      tab: "infos",
      competences: {},
      showForm: false,
    };
  },
  props: {
    selected: {
      Type: [Object, Array],
      Requierd: false,
    },
    loading: {
      Type: Boolean,
      Requierd: false,
      default: false,
    },
    exclureIds: {
      Type: Array,
      Requierd: false,
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      users: "user/users",
    }),
    usersFiltered() {

      function compareNom(a, b){ 
        if(a.collaborateur_nom == b.collaborateur_nom){
          return (a.collaborateur_prenom < b.collaborateur_prenom ? -1 : 1);
        }
        return (a.collaborateur_nom < b.collaborateur_nom ? -1 : 1);
      }

      if (this.exclureIds) return this.users.filter((e) => !this.exclureIds.some((f) => f.id == e.id)).sort(compareNom);
      
      return this.users.slice(0).sort(compareNom);
    },
  },
  methods: {
    autorizeUser(data) {
      this.$emit("autorize", data);
    },
    unautorizeUser(data) {
      this.$emit("unautorize", data);
    },
    userIsSelected(user) {
      for (let index = 0; index < this.selected.length; index++) {
        if (this.selected[index].user_id === user.id) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="css">
.tab-item {
  color: #fff;
  padding: 1px;
  cursor: pointer;
}

.tab-item.active {
  color: #007bff;
}
</style>
