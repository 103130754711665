<template>
  <div class="d-flex flex-column flex-grow-1 mr-2 mb-2 rounded">
    <BaseToolBar title="Organisation" subtitle="Sélectionnez le responsable du collaborateur"></BaseToolBar>
    <user-selector
      v-model.number="collaborateur_hierarchique_id"
      :text="'Responsable hierarchique'"
      @input="userSelected"
    ></user-selector>
    <BaseToolBar title="Utilisateurs" subtitle="Droits d'accès des utilisateurs"></BaseToolBar>
    <users-selector
      :loading="loading"
      :selected="users"
      @autorize="autorize"
      @unautorize="unautorize"
      :exclureIds="[{ id: collaborateur_hierarchique_id }]"
    ></users-selector>
  </div>
</template>
<script>
import UsersSelector from "@/components/application/UsersSelector.vue";
import UserSelector from "@/components/application/UserSelector.vue";

import BaseToolBar from "@/components/bases/ToolBar.vue";
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions } from "vuex";

export default {
  components: { UsersSelector, BaseToolBar, UserSelector },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      _autorize: "collaborateurs/autorizeUser",
      _unautorize: "collaborateurs/unautorizeUser",
      _updateCollaborateur: "collaborateurs/updateCollaborateur",
    }),
    autorize: function (data) {
      this.feedback = {};
      this.loading = true;
      this._autorize({
        user_id: data.id,
        collaborateur_id: this.$route.params.id,
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    unautorize: function (data) {
      this.feedback = {};
      this.loading = true;
      this._unautorize({
        user_id: data.id,
        collaborateur_id: this.$route.params.id,
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    updateCollaborateur: function () {
      this.feedback = {};
      this.loading = true;
      this._updateCollaborateur()
        //.catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    userSelected: function (id) {
      this.autorize({ id: id });
      this.updateCollaborateur();
    },
  },
  mounted() {},
  computed: {
    ...mapFields("collaborateurs", ["collaborateur.id", "collaborateur.collaborateur_hierarchique_id"]),
    ...mapMultiRowFields("collaborateurs", ["users"]),
  },
};
</script>
<style lang="css"></style>
